import {createContext, ReactNode, useContext} from "react"
import {FeatureFlags} from "~/.shared/featureFlags/FeatureFlags"

const FeatureFlagsContext = createContext<{ featureFlags: FeatureFlags } | null>(null);

export const FeatureFlagsContextProvider = ({
  featureFlags,
  children
}: {
  featureFlags: FeatureFlags,
  children?: ReactNode
}) => {
  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}
export const useFeatureFlagsContext = () => {
  const context = useContext(FeatureFlagsContext)
  if (!context) throw new Error("You can only use FeatureFlagsContext within it's provider")
  return context
}
export const useFeatureFlag = (flag: keyof FeatureFlags) => {
  const {featureFlags} = useFeatureFlagsContext()
  return featureFlags[flag]
}